.seminar-question {
    font-size: 24px;
    line-height: 40px;
    text-shadow: 8px 8px 24px #000000;
}

.seminar-answer-ul {
    padding-left: 0px;
    list-style-type: disc;
}

.seminar-answer-p, .seminar-answer-li {
    margin-left: 36px;
    /* font-family: 'Poiret One'; */
    font-family: 'Quicksand';
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 0px;
    text-shadow: 8px 8px 24px #000000;
}