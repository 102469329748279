footer {
    display: flex;
    /* flex-wrap: wrap; */
    margin-top: 120px;
    width: 100%;
    justify-content: space-between;
    padding: 50px 20px;
    font-family: 'Quicksand', sans-serif;
    line-height: 30px;
    min-height: 240px;
    /* position: absolute; */
    position: relative;
    /* bottom: 100%; */
    /* transform: translateY(calc(-100% - 100px)); */
}

.footer-left {
    display: flex;
    position: relative;
    /* width: 50%; */
    align-items: flex-end;
    margin-right: 15px;
}

.footer-left p, .footer-left a {
    /* font-size: 14px; */
    font-size: 16px;

    line-height: 18px;
}

.footer-left p:first-of-type {
    margin-bottom: 10px;
}

/* .site-credits {
    position: absolute;
    bottom: 8px
} */

.footer-right {
    /* width: calc(50% - 40px); */
    /* width: 50%; */
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.footer-right ul {
    margin-top: 0px;
}
.footer-right span, .footer-right a {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    line-height: 30px;
}

.footer-right p {
    font-size: 16px;
}

.footer-right li a::after {
    content: '';
    position: absolute;
    display: block;
    background-color: #fdfdfe;
    bottom: 5px;
    height: 1px;
    width: 0;
    transition: width .3s ease-in;
}

.footer-right li a:hover::after {
    width: 100%;
}

.divider-left {
    border-left: 1px solid #fdfdfe;
}

.footer-padding-right {
    padding-right: 40px;
}

.footer-padding-left {
    padding-left: 40px;
}


@media screen and (max-width: 900px) {
    .footer-padding-right {
        padding-right: 20px;
    }
    .footer-padding-left {
        padding-left: 20px;
    }
}

/* @media screen and (max-width: 820px) {
    footer {
        flex-wrap: wrap;
        flex-direction: colummn-reverse;
        justify-content: flex-end;
    }
} */

@media screen and (max-width: 660px) {
    footer {
        /* flex-wrap: wrap;
        flex-direction: row-reverse;
        justify-content: flex-end; */
        flex-direction: column-reverse;
    }

    .footer-right {
        justify-content: flex-end;
        width: 100%;
        /* max-width: 100%; */
    }
    .footer-left {
        margin-top: 45px;
        width: 100%;
        justify-content: flex-end;
    }
    .footer-left p {
        text-align: right;
    }

}