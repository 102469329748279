.about-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: .5;
}

.about-text-container {
    z-index: 100;
    position: relative;
    /* top: 80px; */
}

/* @media screen and (max-width: 1100px) {
    .about-text-container {
        top: 40px;
    }
}

@media screen and (max-width: 800px) {
    .about-text-container {
        top: 20px;
    }
}

@media screen and (max-width: 600px) {
    .about-text-container {
        top: 0px;
    }
} */