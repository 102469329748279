* {
  margin: 0em;
  min-height: 0;
  min-width: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
}
html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  background-color: #050402;
  color: #fdfdfe;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Botanica;
  src: url('./assets/fonts/Botanica.otf');
}

h1, h2, h3, h4, h5, h6 {
  font-family: Botanica;
}


h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
  line-height: 48px;
}

p {
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  line-height: 36px;
}

a {
  text-decoration: none;
  color: #fdfdfe;
  display: inline-block;
  position: relative;
  font-size: 18px;
  line-height: 36px;
}

.no-scroll {
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.no-opacity {
  opacity: 0;
}
.no-opacity-transition {
  opacity: 0;
  transition: all .6s ease-in-out;
}

.opaque {
  opacity: 1;
}

.opaque-transition {
  opacity: 1;
  transition: all 1.2s ease-in-out;
}

.normal-top {
  top: 0px !important;
}

.black-bg {
  background-color: #050402;
}

.underline::after {
  content: '';
  position: absolute;
  display: block;
  background-color: #fdfdfe;
  height: 1px;
  width: 100%;
  transition: width .3s ease-in;
}

/* .underline-14::after {
  bottom: 8px;
} */
.underline-6::after {
  bottom: 6px;
}
.underline-16::after {
  bottom: 4px;
}
.underline-18::after {
  bottom: 8px;
}

.bold {
  font-weight: 700;
}

.plain-list {
  list-style-type: none;
  padding-left: 0px;
}

.align-right {
  text-align: right;
}

.main {
  width: 100%;
  position: relative;
  top: 100px;
  min-height: calc(100vh - 360px);
}

.width-1200-container {
  width: 1200px;
  max-width: 100%;
  margin: auto;
  position: relative;
  padding: 40px;
}

.fixed {
  position: fixed;
}

.full-screen-container {
  width: 100vw;
  height: 100vh;
  top:0;
  bottom:0;
  left:0;
  right:0;
}

.auto-overflow {
  overflow: auto;
}

.z-index-back {
  z-index: -2;
}

@media screen and (max-width: 900px) {
  h1 {
    font-size: 36px;
    line-height: 36px;
  }

  h2 {
    font-size: 30px;
    line-height: 30px;
  }
  
  h3 {
    font-size: 24px;
  }
}