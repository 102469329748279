.home-bottom-call-to-action-container {
  position: relative;
  /* top: -100px; */
  /* min-height: calc(100vh - 360px); */
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -200px;
}

/* .home-bottom-call-to-action-container div { */
    /* display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center; */
    /* padding-top: 340px; */
/* } */

.home-bottom-call-to-action-container h3:first-of-type {
  margin-bottom: 50px;
} 

.home-bottom-call-to-action-container h3 a {
  font-size: 30px;
}

@media screen and (max-width: 900px) {
  .home-bottom-call-to-action-container h3 a {
    font-size: 24px;
  }
}

@media screen and (max-width: 700px) {
  .home-bottom-call-to-action-container h3 a::after {
      width: 0;
  }
  .home-bottom-call-to-action-container h3 a {
      text-decoration: underline;
      display: inline;
  }

}