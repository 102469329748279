.home-img-container {
    position: relative;
    /* top: -100px; */
    overflow: hidden;
    z-index: 20;
}

.home-img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .5;
}

.home-img-text-container {
    position: absolute;
    font-size: 30px;
    text-shadow: 8px 8px 24px #000000;
}


.scroll-down-container {
cursor: pointer;
position: absolute;
bottom: 0px;
/* bottom: 100px; */
left: 0;
right: 0;
z-index: 3;
margin: auto;
text-align: center;
}

.scroll-down-container .arrow-container {
  display: block;
  text-decoration: none;
  color: white;
  margin-top: 50px;
}

.scroll-down-container .arrow-container span {
  margin-top: 20px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  /* bottom: 0; */
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: scroll-animation 1.5s infinite;
  animation: scroll-animation 1.5s infinite;
  box-sizing: border-box;
}

@keyframes scroll-animation {
    0% {
      transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
}

  /* @-webkit-keyframes scroll-animation {
    0% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
  }
  @keyframes scroll-animation {
    0% {
      transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
  }
} */

/* #scroll-link {
  display: block;
  margin-top: 20px;
  position: relative;
  top: 70px;
  color: white;
  transition: all .3s ease-in-out 0s;
  &:hover {
    color: gray;
  }
} */

/* } */